import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { UserDataManager } from "src/app/user/UserDataManager";
import { User } from "src/app/models/user";
import { Table } from "primeng/table";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import {
  eSocPatientSubmissions,
  eSocStatus,
  EsocSubmissionStatusColor,
  EsocSubmissionStatusValues,
  consentStatus,
  eDocComplete,
} from "src/app/models/esocPatient";
import { EsocService } from "src/app/services/e-soc/esoc.service";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import * as AWS from "aws-sdk";
import { S3 } from "aws-sdk";
import { SelectItem } from "primeng/api";
import { refreshTime } from "src/app/models/patient";
import {
  adminRoles,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear,
  dateFormatStartWithYearWithOutDashes,
  DEFAULT_MRN_LENGTH,
  DEFAULT_PATIENT_ID_LENGTH,
  DEFAULT_SEARCH_MIN_CHARACTER,
  FILE_DOWNLOAD_SUCCESS,
  FileType,
  FileTypeNames,
  RoleID,
  rowsPerPageOptions,
  SEARCH_PATIENT_BY_CASE_MANAGER,
  SEARCH_PATIENT_BY_ID,
  SEARCH_PATIENT_BY_MRN,
  EDOC_REPORT,
  EXCEL_TYPE,
  EXCEL_EXTENSION,
  FILE_NOT_OPENED,
  DEBOUNCE_TIME_SET,
} from "../../common/Constants";
import { Dropdown } from "primeng/dropdown";
import { DatePipe } from "@angular/common";
import { RoleEnum } from "src/app/models/agency";
import { PatientService } from "src/app/services/patient/patient.service";
import { Paginator } from "primeng/paginator";
import { NavigationEnd, Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";
import { UtilService } from "../../services/util/util.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: "app-esoc",
  templateUrl: "./esoc.component.html",
  styleUrls: ["./esoc.component.scss"],
})
export class EsocComponent implements OnInit, OnDestroy {
  fileNotOpened = FILE_NOT_OPENED;
  esocPatientsData: any[] = [];
  currentUser: User;
  selectedPatients: eSocPatientSubmissions[];
  searchEsocPatient: string = "";
  currentAgency: string;
  totalEsocs: number = 0;
  selectedIndexForPopup: number;
  selectedIndex: any;
  prefferedContact: any;
  currentAgencyRole;
  subscriptions: Subscription[] = [];
  eSocSubmissionStatusColor = EsocSubmissionStatusColor;
  eSocSubmissionStatusValues = EsocSubmissionStatusValues;
  statusOptions: SelectItem[] = eSocStatus;
  consentStatus: SelectItem[] = consentStatus;
  edocComplete: SelectItem[] = eDocComplete;
  selectedStatus: string;
  fileStatus: string;
  consent: boolean | string;
  edocCompleteStatus: boolean | string = false;
  piReportStatus: string;
  otherStatus: string;
  selectedDate: any;
  formatDate;
  status;
  selectedFileTypeFilter: string;
  selectRefreshTime: number = 30;
  refreshTime = refreshTime;
  intervalId: any;
  eSOCUploadType: string;
  eSOCUploadDisplay: boolean = false;
  uploadOthersDisplay: boolean = false;
  selectedEsock: any;
  rangeDates: Date[];
  searchByVisitDates: any = {};
  startDate: any;
  endDate: any;
  RoleEnum = RoleEnum;
  defaultSortField = "activityDate";
  defaultSortOrder = "-1";
  // showcasemanagerNameFilter: boolean = true;
  // caseManagerFilterNames: any;
  selectedCaseManager: any = "All";
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  adminRoles = adminRoles;
  hidePatientName: boolean = false;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchPatientById = SEARCH_PATIENT_BY_ID;
  searchPatientByMRN = SEARCH_PATIENT_BY_MRN;
  pageNo: number = 0;
  noOfEsocksPerRow: number = 30;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  searchByCaseManager: string = "";
  searchByCaseManagerPlaceHolder = SEARCH_PATIENT_BY_CASE_MANAGER;
  currentRoute: string;
  defaultMrnLength = DEFAULT_MRN_LENGTH;
  defaultPatientIdLength = DEFAULT_PATIENT_ID_LENGTH;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  fileType = FileType;
  companyAdminUser = RoleID.companyAdmin;
  iohAdmin = RoleID.iohAdmin;
  Admin = RoleID.Admin;
  agencyAdmin = RoleID.AgencyAdmin;
  loading: boolean = false;
  workbook: Workbook;

  @ViewChild("dt") table: Table;
  @ViewChild("dropdown") dropdown: Dropdown;
  @ViewChild("paginator") paginator: Paginator;

  search = new Subject<string>();

  constructor(
    private messageHandlerService: MessageHandlerService,
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private esocService: EsocService,
    private datePipe: DatePipe,
    private patientService: PatientService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });

    this.search.pipe(debounceTime(DEBOUNCE_TIME_SET), distinctUntilChanged()).subscribe((value) => {
      this.searchByPatientOrCaseManager(value);
    });
  }

  ngOnInit(): void {
    this.getCurrentUserProfile();
    this.getDataFromLocalStorage();
    this.getSelectedAgency();
    this.hideFromDataRoles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getDataFromLocalStorage() {
    const eDocState = JSON.parse(this.localStorageService.get("eDocState"));
    this.searchEsocPatient = eDocState?.searchEsocPatient ? eDocState?.searchEsocPatient : "";
    this.searchByCaseManager = eDocState?.searchByCaseManager ? eDocState?.searchByCaseManager : "";
    this.selectedStatus = eDocState?.selectedStatus?.toString() ? eDocState?.selectedStatus : "";
    this.selectedFileTypeFilter = eDocState?.fileType ? eDocState?.fileType : "";
    this.fileStatus = this.selectedFileTypeFilter == this.fileType.file ? this.selectedStatus : "";
    this.piReportStatus = this.selectedFileTypeFilter == this.fileType.piReportFile ? this.selectedStatus : "";
    this.otherStatus = this.selectedFileTypeFilter == this.fileType.otherFile ? this.selectedStatus : "";
    this.rangeDates = eDocState?.rangeDates ? eDocState?.rangeDates : null;
    this.consent = eDocState?.consent ? eDocState?.consent : this.consentStatus[1]?.value;
    this.edocCompleteStatus = eDocState?.edoc_status ? eDocState?.edoc_status : false;

    if (this.rangeDates && this.rangeDates.length && this.rangeDates[0]) {
      let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      this.searchByVisitDates.startDate = this.utilService.convertToISOString(startDateFilter, true);
      this.rangeDates[0] = new Date(this.rangeDates[0]);
    }

    if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1]) {
      let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
      this.searchByVisitDates.endDate = this.utilService.convertToISOString(endDateFilter, false);
      this.rangeDates[1] = new Date(this.rangeDates[1]);
    }

    this.prePopulatePerPage()
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfEsocksPerRow = global.perPage;
    }
  }

  setDataInLocalStorage(key, value) {
    const eDocState: any = JSON.parse(this.localStorageService.get("eDocState"));
    if (eDocState) {
      eDocState[key] = value;
      this.localStorageService.set("eDocState", JSON.stringify(eDocState));
    } else {
      let eDocNewState = {};
      eDocNewState[key] = value;
      this.localStorageService.set("eDocState", JSON.stringify(eDocNewState));
    }
  }

  navigate(path) {
    this.router.navigate([path]);
  }

  getCurrentUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        this.currentUser = res;
      }),
    );
  }

  getSelectedAgency() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((res) => {
        this.currentAgency = res?.agency;
        this.esocPatientsData = [];
        this.currentAgencyRole = res;
        // this.geteSocPatientSubmissions();
      }),
    );
  }

  searchByPatientOrCaseManager(searchParam: string) {
    this.setDataInLocalStorage("searchEsocPatient", this.searchEsocPatient);
    this.setDataInLocalStorage("searchByCaseManager", this.searchByCaseManager);

    if (searchParam === undefined || searchParam === null || searchParam === "") {
      this.geteSocPatientSubmissions();
    } else if (searchParam && searchParam.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.geteSocPatientSubmissions();
    }
  }

  geteSocPatientSubmissions(sendFile?: string, orderBy?: string, order?: string) {
    if (orderBy && order) {
      this.defaultSortField = orderBy;
      this.defaultSortOrder = order;
    }
    this.progressBarService.setProgressBarVisibility(true);
    this.loading = true;
    this.cdr.detectChanges();
    this.subscriptions.push(
      this.esocService
        .geteSocPatientSubmissions({
          agencyId: this.currentAgency,
          status: this.selectedStatus,
          fileType: this.selectedFileTypeFilter,
          search: this.searchEsocPatient,
          dateRangeSearch: this.searchByVisitDates,
          //searchByCaseManagerName: this.selectedCaseManager,
          pagination: { pageNumber: this.pageNo, pageSize: this.noOfEsocksPerRow },
          caseManagerSearch: this.searchByCaseManager,
          orderBy: this.defaultSortField,
          order: this.defaultSortOrder,
          consent: this.consent,
          eDocComplete: this.edocCompleteStatus,
        })
        .subscribe((res) => {
          if (res?.success) {
            this.esocPatientsData = res.content.data.map((patient) => {
              const patientFirstName = patient?.patientFirstName ? patient?.patientFirstName : "";
              const patientLastName = patient?.patientLastName ? patient?.patientLastName : "";
              const patientName = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");
              return {
                ...patient,
                patientName: patientName,
              };
            });

            this.mergeFiles();
            this.totalEsocs = res?.content?.totalElements;
            if (!this.isChangingPage) {
              this.changePage();
            }

            if (sendFile && sendFile !== "") {
              this.processEsocRequest(0, sendFile);
            }
          }
          this.loading = false;
          this.progressBarService.setProgressBarVisibility(false);
          this.cdr.detectChanges();
        }),
    );
    //}
  }

  mergeFiles() {
    this.esocPatientsData.forEach((esocPatient) => {
      esocPatient.patientEsocData.file = esocPatient?.patientEsocData?.file ? esocPatient?.patientEsocData?.file : [];
      esocPatient.patientEsocData.otherFile = esocPatient?.patientEsocData?.otherFile ? esocPatient?.patientEsocData?.otherFile : [];
      esocPatient.patientEsocData.piReportFile = esocPatient?.patientEsocData?.piReportFile ? esocPatient?.patientEsocData?.piReportFile : [];
      esocPatient.patientEsocData.mergeFiles = esocPatient?.patientEsocData?.file.concat(
        esocPatient.patientEsocData.otherFile,
        esocPatient.patientEsocData.piReportFile,
      );

      esocPatient.patientEsocData.mergeFiles.sort((a, b) => {
        return new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime();
      });
    });
  }

  downloadEsocPatient(index: number, type) {
    this.selectedIndex = this.esocPatientsData[index];
    if (this.esocPatientsData[index].patientEsocData[type] && this.esocPatientsData[index]?.patientEsocData?.[type][0] != undefined) {
      var tempfileName = this.esocPatientsData[index].patientEsocData[type][0].fileLocation;
      var lastIndex = tempfileName.lastIndexOf("/");
      const fileName = tempfileName.substring(lastIndex + 1);
      console.log("file name: ", fileName);
      // var fileName = 'test68d51f8b-a58a-4855-9764-bf9d5678ed6c.pdf';
      this.downloadPdfFromS3(fileName)
        .then(() => {
          console.log("PDF File downloaded successfully");
        })
        .catch((error) => {
          console.log("Error Downloading File: ", error);
        });
    } else {
      console.log("File Name does not exist in the DB");
      this.messageHandlerService.generateToastMessage("error", "", "File Name does not exist in the DB");
    }
  }

  customSort(event) {
    this.geteSocPatientSubmissions("", event?.sortField, event?.sortOrder.toString());
  }

  async downloadPdfFromS3(fileName: string): Promise<void> {
    try {
      const params = {
        fileName: fileName,
      };
      this.esocService.getEPHfileObjectUrl(params).subscribe((res) => {
        if (res.success) {
          const link = document.createElement("a");
          link.href = res.url;
          link.download = fileName;
          link.click();
          console.log("File Successfully Downloaded");
          this.messageHandlerService.generateToastMessage("success", "", FILE_DOWNLOAD_SUCCESS);
        } else {
          console.log("Error Downloading File --> : ", res.message);
          this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + res.message);
        }
      });
    } catch (error) {
      console.log("Error Downloading File: ", error);
      this.messageHandlerService.generateToastMessage("error", "", "Error Downloading File: " + error);
    }

    // const s3 = new S3();
    // s3.getSignedUrl('getObject', params, (err, url) => {
    //   if (err) {
    //     console.error('Error generating pre-signed URL:', err);
    //     return;
    //   }
    //   else{
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = fileName;
    //     link.click();
    //     console.log("File Successfully Downloaded");
    //     this.messageHandlerService.generateToastMessage("success", "", FILE_DOWNLOAD_SUCCESS);
    //   }
    // });
  }

  processEsocRequest(index, type) {
    try {
      this.selectedIndex = this.esocPatientsData[index];
      const phone = this.esocPatientsData[index]?.patientEsocData?.patient?.contactInfo?.phone;
      const email = this.esocPatientsData[index]?.patientEsocData?.patient?.contactInfo?.email;
      this.progressBarService.setProgressBarVisibility(true);
      if (!phone && !email) {
        this.messageHandlerService.generateToastMessage("error", "", "Patient does not have a phone number or email");
        this.progressBarService.setProgressBarVisibility(false);
        return;
      }

      const submissionId = this.esocPatientsData[index]._id;
      let request = { patientEsocRecordId: submissionId, fileType: type };

      this.esocService.processEsocRequest(request, this.currentAgency).subscribe((res) => {
        if (res?.success) {
          this.progressBarService.setProgressBarVisibility(false);
          this.geteSocPatientSubmissions();
          this.messageHandlerService.generateToastMessage("success", "", `A ${FileTypeNames[type]} Link has been sent successfully`);
        } else {
          this.progressBarService.setProgressBarVisibility(false);
          if (res?.statusCode == 403) {
            this.messageHandlerService.generateToastMessage("error", "Access denied", "eDoc feature is disabled for this Agency");
          } else {
            this.messageHandlerService.generateToastMessage("warn", "", res?.errorMessage);
          }
        }
      });
    } catch (error) {
      console.log("Error Resending Link: ", error);
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
    // this.selectedIndex = this.esocPatientsData[index];
    // this.prefferedContact = this.esocPatientsData[index].patient.contactInfo.prefferedOption;
    // var submissionId = this.esocPatientsData[index]._id;
    // if (this.prefferedContact == 'email') {
    //   this.sendEmail(submissionId)
    // }
    // else {
    //   this.sendSms(submissionId);
    // }
  }

  // sendEmail(submissionId) {
  //   try {
  //     this.progressBarService.setProgressBarVisibility(true);
  //     let request = {"submissionId": submissionId};
  //     this.subscriptions.push(this.esocService.processEsocRequest(request).subscribe(res => {
  //       if (res?.success) {
  //         this.progressBarService.setProgressBarVisibility(false);
  //         this.messageHandlerService.generateToastMessage('success',"","Link Resent successfully");
  //       } else {
  //         this.progressBarService.setProgressBarVisibility(false);
  //         this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', res?.errorMessage);
  //       }
  //     }))
  //   } catch (error) {
  //     this.progressBarService.setProgressBarVisibility(false);
  //     this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', error);
  //   }
  //
  // }
  //
  // sendSms(submissionId) {
  //   try {
  //     this.progressBarService.setProgressBarVisibility(true);
  //     let request = {"submissionId": submissionId}
  //     this.esocService.resendEsocSms(request).subscribe(res => {
  //       if (res?.success) {
  //         this.progressBarService.setProgressBarVisibility(false);
  //       } else {
  //         this.progressBarService.setProgressBarVisibility(false);
  //         this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', res?.errorMessage);
  //       }
  //     })
  //   } catch (error) {
  //     this.progressBarService.setProgressBarVisibility(false);
  //     this.messageHandlerService.generateToastMessage('error', 'Communication error:  ', error);
  //   }
  // }

  changeConsent(rowIndex) {
    this.subscriptions.push(
      this.esocService
        .changeConsent({ id: this.esocPatientsData[rowIndex]?._id, consent: !this.esocPatientsData[rowIndex]?.eSock?.consent })
        .subscribe((res) => {
          if (res?.success) {
            this.esocPatientsData[rowIndex].eSock.consent = !this.esocPatientsData[rowIndex].eSock.consent;
          } else {
            this.messageHandlerService.generateToastMessage("error", "", "Error occurred while changing consent.");
          }
        }),
    );
  }

  onStatusChange(event, type) {
    this.selectedStatus = event.value;
    this.selectedFileTypeFilter = type;
    this.consent = "";
    this.geteSocPatientSubmissions();
    this.setDataInLocalStorage("selectedStatus", this.selectedStatus);
    this.setDataInLocalStorage("fileType", this.selectedFileTypeFilter);
    this.setDataInLocalStorage("consent", this.consent);
    if (this.pageNo) {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.paginator.changePage(this.pageNo);
    }
  }

  consentStatusChange() {
    this.resetFileFilters();
    this.geteSocPatientSubmissions();
    this.setDataInLocalStorage("consent", this.consent);
    this.setDataInLocalStorage("edoc_status", this.edocCompleteStatus);
    if (this.pageNo) {
      this.pageNo = 0;
      this.setDataInLocalStorage("pageNo", this.pageNo);
      this.paginator.changePage(this.pageNo);
    }
  }

  resetFileFilters() {
    this.selectedStatus = "";
    this.selectedFileTypeFilter = "";
    this.fileStatus = "";
    this.piReportStatus = "";
    this.otherStatus = "";
    this.setDataInLocalStorage("selectedStatus", this.selectedStatus);
    this.setDataInLocalStorage("fileType", this.selectedFileTypeFilter);
  }

  createTooltip(esocPatientsData) {
    let role = esocPatientsData?.UserdefaultRole
      ? "(" + RoleEnum[esocPatientsData?.UserdefaultRole] + ")"
      : esocPatientsData?.isSuperAdmin
        ? "(" + RoleEnum.SUPER_ADMIN + ")"
        : esocPatientsData?.isIohAdmin
          ? "(" + RoleEnum.IOH_ADMIN + ")"
          : "";

    const caseManagerFirstName = esocPatientsData?.patientEsocData?.patient?.CaseManagerFirstName
      ? esocPatientsData?.patientEsocData?.patient?.CaseManagerFirstName
      : "";
    const caseManagerLastName = esocPatientsData?.patientEsocData?.patient?.CaseManagerLastName
      ? esocPatientsData?.patientEsocData?.patient?.CaseManagerLastName
      : "";
    const userFirstName = esocPatientsData?.UserfirstName ? esocPatientsData?.UserfirstName : "";
    const userLastName = esocPatientsData?.UserlastName ? esocPatientsData?.UserlastName : "";

    let caseManager = `${caseManagerFirstName} ${caseManagerLastName}`;
    let createdDate = `${this.datePipe.transform(esocPatientsData?.patientEsocData?.createdAt, this.dateFormat)}`;

    let res = `<b>Submitted by:</b> ${userFirstName} ${userLastName} <br> ${role} <br>`;
    res = res + `<b>Case Manager:</b> ${caseManager} <br>`;
    res = res + ` <b>Created Date:</b> ${createdDate}`;

    if (this.hidePatientName) {
      return res;
    } else {
      let iohData = `<b>IOH ID:</b> ${esocPatientsData?.patientEsocData?.patient?._id} <br> <b>MRN:</b> ${esocPatientsData?.patientEsocData?.patient?.mrn} <br>`;
      res = iohData + res;
      return res;
    }
  }

  selectVisitDate() {
    let newEndDate: any = "";

    if (this.rangeDates[1]) {
      let endDate = new Date(this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear));
      newEndDate = new Date(endDate.setHours(23, 59, 59, 999));
    }

    this.searchByVisitDates = {
      startDate: new Date(this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear)),
      endDate: newEndDate,
    };
    this.pageNo = 0;
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.geteSocPatientSubmissions();
  }

  onChangeRefreshTime() {
    this.stopRefresh();
    this.startRefresh();
  }

  stopRefresh() {
    clearInterval(this.intervalId);
  }

  startRefresh() {
    this.intervalId = setInterval(() => {
      this.geteSocPatientSubmissions();
    }, this.selectRefreshTime * 1000);
  }

  onCalendarClear() {
    this.rangeDates = null;
    this.searchByVisitDates = null;
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.geteSocPatientSubmissions();
  }

  hideFromDataRoles() {
    this.hidePatientName = adminRoles.includes(this.currentUser?.currentUserRole);
  }

  isIOHUser() {
    if (this.currentUser.is_super_admin) {
      return false;
    } else return !(this.currentUser.is_ioh_user && this.currentAgencyRole.role === RoleID.AgencyAdmin);
  }

  openEPHUploadPopup(rowIndex, type) {
    // this.progressBarService.setProgressBarVisibility(true);
    // this.subscriptions.push(this.esocService.getPatientEdocById({
    //   esocId: this.esocPatientsData[rowIndex]._id,
    // }).subscribe(res => {
    //   if(res?.success) {
    //     let d = this.esocPatientsData[rowIndex]
    //     let resp = res.content.data[0]

    //     d = {
    //       ...d,
    //       patientEsocData: {
    //         ...d.patientEsocData,
    //         file: resp?.file ? resp?.file : [],
    //         otherFile: resp?.otherFile ? resp?.otherFile : [],
    //         piReportFile: resp?.piReportFile ? resp?.piReportFile : [],
    //         mergeFiles: resp?.file?.concat(resp.otherFile, resp.piReportFile).sort((a, b) => {
    //           return (
    //             new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime()
    //           );
    //         })
    //       }
    //     }

    //     this.eSOCUploadType = type;
    //     this.eSOCUploadDisplay = true;
    //     this.selectedEsock = d;
    //     this.selectedIndexForPopup = rowIndex;
    //   }
    //   this.progressBarService.setProgressBarVisibility(false);
    // }))

    this.eSOCUploadType = type;
    this.eSOCUploadDisplay = true;
    this.selectedEsock = this.esocPatientsData[rowIndex];
    this.selectedIndexForPopup = rowIndex;
  }

  ePHUploadPopupClose() {
    this.eSOCUploadDisplay = false;
  }

  refreshListing(event) {
    this.paginator?.changePage(0);
    this.geteSocPatientSubmissions(event?.type ? event?.type : "");
  }

  // getPatientsWithCaseManagerNames()
  // {
  //   this.patientService.getPatientsWithCaseManagerNames().subscribe((res) => {
  //     const caseManagerSet = new Set();
  //     if(res.content && res.success)
  //     {
  //       this.showcasemanagerNameFilter = res?.content?.patients[0]?.names?.length && res?.content?.patients[0]?.names?.length !== 0;

  //       const allOption = { label: "All", value: "All" };
  //       res?.content?.patients[0]?.names?.forEach(obj => {
  //         const fullName = {firstName: obj?.CaseManagerFirstName, lastName: obj?.CaseManagerLastName};
  //         const fullNameStr = JSON.stringify(fullName);
  //         if (obj?.CaseManagerFirstName || obj?.CaseManagerLastName) {
  //           if (!caseManagerSet.has(fullNameStr)) {
  //             caseManagerSet.add(fullNameStr);
  //           }
  //         }
  //       });

  //       const caseManagerNames = Array.from(caseManagerSet).map((fullNameStr: any) => {
  //         const fullName = JSON.parse(fullNameStr);
  //         return {
  //           label: `${fullName.firstName} ${fullName.lastName}`,
  //           value: { firstName: fullName.firstName, lastName: fullName.lastName }
  //         };
  //       });
  //       this.caseManagerFilterNames = [allOption , ...caseManagerNames]
  //     }
  //   })
  // }

  // onSelectedCaseManagerName() {
  //   this.geteSocPatientSubmissions();
  // }

  isSearchEmpty() {
    if (this.searchEsocPatient === undefined || this.searchEsocPatient === null || this.searchEsocPatient === "") {
      this.pageNo = 0;
      this.geteSocPatientSubmissions();
    }
  }

  onlyAdminRoles() {
    return adminRoles.includes(this.currentUser?.currentUserRole);
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfEsocksPerRow = event.rows;
      this.geteSocPatientSubmissions();
    }
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  resetPaginator() {
    this.paginator.changePageToFirst(event);
  }

  exportReport() {
    const req = {
      agencyId: this.currentAgency,
      status: this.selectedStatus,
      fileType: this.selectedFileTypeFilter,
      search: this.searchEsocPatient,
      dateRangeSearch: this.searchByVisitDates,
      pagination: { pageNumber: this.pageNo, pageSize: this.noOfEsocksPerRow },
      caseManagerSearch: this.searchByCaseManager,
      orderBy: this.defaultSortField,
      order: this.defaultSortOrder,
    };
    this.subscriptions.push(
      this.esocService.getPatientESOCExportData(req).subscribe((res) => {
        if (res && res.success) {
          this.workbook = new Workbook();
          const worksheet = this.workbook.addWorksheet("Patient eDoc");
          this.generateExcelReportData(res.content.data ?? [], worksheet);
          this.exportExcelReport();
        }
      }),
    );
  }

  generateExcelReportData(data, worksheet) {
    this.addFilterDatesRow(worksheet);
    if (data && data?.length > 0) {
      this.prepareEDocDataWithHeaders(data, worksheet);
    }
  }

  addFilterDatesRow(worksheet) {
    let dateRangeString = "";
    let titleString = "";
    let filterRow;
    if (this.searchByVisitDates?.startDate && !this.searchByVisitDates?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (this.searchByVisitDates?.startDate && this.searchByVisitDates?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (!this.searchByVisitDates?.startDate && !this.searchByVisitDates?.endDate) {
      const startDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ---- - ${startDate}`;
    }

    titleString = `Report: ${EDOC_REPORT}`;

    filterRow = worksheet.addRow([titleString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    filterRow = worksheet.addRow([dateRangeString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    worksheet.addRow([]);
  }

  prepareEDocDataWithHeaders(data, worksheet) {
    this.addHeaders(worksheet);
    this.addData(data, worksheet);
  }

  addHeaders(worksheet) {
    let headers = this.getReportHeaders();
    this.addHeaderRow(headers, worksheet);
  }

  getReportHeaders() {
    return [
      { header: "MRN", key: "MRN", width: 30 },
      { header: this.hidePatientName ? "Patient ID" : "Patient Name", key: this.hidePatientName ? "Patient ID" : "Patient Name", width: 50 },
      { header: "Edoc Status", key: "Edoc Status", width: 30 },
      { header: "File name", key: "File name", width: 50 },
      { header: "File Type", key: "File Type", width: 30 },
      { header: "Preferred Method", key: "Preferred Method", width: 20 },
      { header: "Sender", key: "Sender", width: 30 },
      { header: "Send (Date)", key: "Send (Date)", width: 30 },
      { header: "Received", key: "Received", width: 30 },
      { header: "Status", key: "Status", width: 30 },
    ];
  }

  addHeaderRow(headers, worksheet) {
    const headerRowValues = headers?.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);
    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FFFF00" },
        },
      };
    });
    headers?.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  addData(data, worksheet) {
    let formattedData = [];
    data.forEach((element) => {
      element.file = element?.file ? element?.file : [];
      element.otherFile = element?.otherFile ? element?.otherFile : [];
      element.piReportFile = element?.piReportFile ? element?.piReportFile : [];
      element.mergeFiles = element?.file.concat(element.otherFile, element.piReportFile);

      element.mergeFiles.sort((a, b) => {
        return new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime();
      });

      const preferredMethod =
        element?.eSocEmailPhonePreference?.email?.preferred && element?.eSocEmailPhonePreference?.phone?.preferred
          ? "both"
          : element?.eSocEmailPhonePreference?.email?.preferred
            ? "email"
            : element?.eSocEmailPhonePreference?.phone?.preferred
              ? "phone"
              : "";

      if (element.mergeFiles && element.mergeFiles.length) {
        element.mergeFiles.forEach((mergeFile) => {
          const dataObj = {
            mrn: this.addPatientMrnColumn(element),
            patient: this.addPatientIdOrPatientNameColumn(element),
            estatus: this.addEdocStatusColumn(element),
            filename: mergeFile ? mergeFile?.fileOriginalName : "",
            file_type: mergeFile ? mergeFile?.fileType : "",
            prefer_method: preferredMethod,
            sender:
              (element?.user
                ? element?.user?.firstName + " " + element?.user?.lastName
                : element?.sentuser
                  ? element?.sentuser?.firstName + " " + element?.sentuser?.lastName
                  : "") || "",
            date: mergeFile?.activityDate ? this.datePipe.transform(mergeFile?.activityDate, dateFormat) : "",
            recieved: (mergeFile?.recieved === true ? "Yes" : "No") || "",
            status: (mergeFile?.status ? mergeFile?.status : this.fileNotOpened) || "",
          };
          formattedData.push(dataObj);
        });
      } else {
        const dataObj = {
          mrn: this.addPatientMrnColumn(element),
          patient: this.addPatientIdOrPatientNameColumn(element),
          estatus: this.addEdocStatusColumn(element),
          filename: "",
          file_type: "",
          prefer_method: preferredMethod,
          sender: "",
          date: "",
          recieved: "",
          status: "",
        };
        formattedData.push(dataObj);
      }
    });

    formattedData.forEach((e) => {
      const r = [e.mrn, e.patient, e.estatus, e.filename, e.file_type, e.prefer_method, e.sender, e.date, e.recieved, e.status];
      worksheet.addRow(r);
    });
  }

  exportExcelReport() {
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      let titleString = `${EDOC_REPORT}-`;
      fs.saveAs(blob, titleString + this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) + EXCEL_EXTENSION);
    });
  }

  addPatientMrnColumn(patient) {
    return !this.hidePatientName ? patient?.patientMrn : "";
  }

  addPatientIdOrPatientNameColumn(patient) {
    if (this.hidePatientName) {
      return patient?.patientId;
    } else {
      const patientFirstName = patient?.patientFirstName;
      const patientLastName = patient?.patientLastName;
      let patientName;
      if (patientFirstName && patientLastName) {
        patientName = patientLastName + " " + patientFirstName;
      } else if (patientLastName) {
        patientName = patientLastName;
      } else if (patientFirstName) {
        patientName = patientFirstName;
      }
      return patientName;
    }
  }

  addEdocStatusColumn(patient) {
    const edocStatus = patient.consent ? "Opt In" : "Opt Out";
    return edocStatus;
  }
}
