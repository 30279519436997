import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Patient, patientSubmissionDropdown, submissionStatus, SubmissionStatusColor, submissionTypes, viewByVisitDropdown } from "../models/patient";
import { PatientSubmissions, SubmissionList } from "../models/patientSubmissions";
import { User } from "../models/user";
import { Subscription } from "rxjs";
import { UserDataManager } from "../user/UserDataManager";
import {
  adminRoles,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateAndTimeFormat,
  dateFormat,
  dateFormatStartWithYear,
  DEFAULT_MRN_LENGTH,
  DEFAULT_ORDER_BY_PATIENT_VISIT,
  DEFAULT_ORDER_PATIENT_VISIT,
  DEFAULT_PATIENT_ID_LENGTH,
  DEFAULT_SEARCH_MIN_CHARACTER,
  OnlyAdminAllowedRoles,
  RoleID,
  rowsPerPageOptions,
  SEARCH_PATIENT_BY_CASE_CLINICIAN,
  SEARCH_PATIENT_BY_CASE_MANAGER,
  SEARCH_PATIENT_BY_ID,
  SEARCH_PATIENT_BY_NAME_OR_MRN,
  ANONYMOUS,
  QUESTIONAIR_TYPE_ERROR,
  QUESTIONAIR_TYPE_WARNING,
  QUESTIONAIR_TYPE_WARN,
  DEFAULT_VISIT_DATE_FILTER,
  IMPACT,
  DEBOUNCE_TIME_SET,
} from "../common/Constants";
import { ProgressBarService } from "../services/progress-bar.service";
import { PatientService } from "../services/patient/patient.service";
import { MessageHandlerService } from "../services/message-handler.service";
import { UtilService } from "../services/util/util.service";
import { RoleEnum } from "../models/agency";
import { DatePipe } from "@angular/common";
import { Paginator } from "primeng/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";
import { swapLatitudeAndLongitude } from "../services/util/helper.function";
import { questionDescription } from "../helper/question.description";
import { reformatNormalizedElementAnswersData, reformatVisitsAnswersData, reformatWithSpecialCharacters } from "../helper/functions";
import { includes } from "lodash-es";
import { SelectItem } from "primeng/api";
import { visitDetailColors } from "src/app/models/patient";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

declare var google: any;
@Component({
  selector: "app-visit",
  templateUrl: "./visit.component.html",
  styleUrls: ["./visit.component.scss"],
})
export class VisitComponent implements OnInit, OnDestroy {
  @ViewChild("paginator") paginator: Paginator;
  selectPatientSubmissionOptions: string = patientSubmissionDropdown[0].value;
  selectViewByVisit: string = viewByVisitDropdown[0].value;
  totalSubmission: number = 0;
  submissionList: SubmissionList[] = [];
  selectedSubmissions: SubmissionList[];
  currentUser: User;
  subscriptions: Subscription[] = [];
  RoleID = RoleID;
  selectedSubmissionStatus: string = submissionStatus[0].value;
  selectedSubmissionTypes = [];
  selectedSubmissionTypesForAPI;
  selectedClinician: string[] = [];
  clinicianFilter: SelectItem[] = [];
  originalClinicianFilter: SelectItem[] = [];
  pageNo: number = 0;
  noOfRecordPerRow: number = 30;
  searchByPatient: string = "";
  searchByVisitDate: any;
  searchByVisitDates: any = {};
  selectedAgency: string = "";
  currentAgency: { agency: string; role: string };
  submissionStatus = submissionStatus.filter(
    (status) =>
      !status.value.includes("incomplete") &&
      !status.value.includes("waiting_for_review") &&
      !status.value.includes("reviewed") &&
      !status.value.includes("recheck"),
  );
  submissionTypes = submissionTypes;
  display = false;
  patientSubmission: PatientSubmissions | any;
  patientSubmissionRowSelected: number;
  selectedVisitDates: string;
  selectedVisitType: string;
  options: any;
  locationPopupDisplay: boolean = false;
  overlays: any;
  submissionStatusColorEnum = SubmissionStatusColor;
  isChangingPage: boolean = false;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  patientSubmissionDropdown = patientSubmissionDropdown;
  viewByVisitDropdown = viewByVisitDropdown;
  eSOCUploadDisplay: boolean = false;
  loading: boolean = false;

  patients: Patient[] = [];
  totalPatient: number = 0;
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;
  RoleEnum = RoleEnum;
  hidePatientName: boolean = false;
  allowAdminRoles: boolean = false;
  rangeDates: Date[];
  selectedVisitDate: any = null;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchPatientByNameOrMrn = SEARCH_PATIENT_BY_NAME_OR_MRN;
  searchPatientById = SEARCH_PATIENT_BY_ID;
  currentPath: string;
  defaultMrnLength = DEFAULT_MRN_LENGTH;
  defaultPatientIdLength = DEFAULT_PATIENT_ID_LENGTH;
  searchByCaseManager: string = "";
  searchByCaseManagerPlaceHolder = SEARCH_PATIENT_BY_CASE_MANAGER;
  searchByClinician: string = "";
  searchByClinicianPlaceHolder = SEARCH_PATIENT_BY_CASE_CLINICIAN;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  defaultSortField = DEFAULT_ORDER_BY_PATIENT_VISIT;
  defaultSortOrder = DEFAULT_ORDER_PATIENT_VISIT;
  selectedCaseManagerName: string[] = [];
  caseManagerFilter: SelectItem[] = [];
  originalCaseManagerFilter: SelectItem[] = [];
  sortOrder: string = "-1";
  orderBy: string = "submissionDate";
  visitDetailColors = visitDetailColors;
  hoursInFourDays = 96;

  search = new Subject<string>();

  constructor(
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private patientService: PatientService,
    private messageHandlerService: MessageHandlerService,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    this.search.pipe(debounceTime(DEBOUNCE_TIME_SET), distinctUntilChanged()).subscribe((value) => {
      this.resetPageAndFetchSubmissionByPatient(value);
    });
    this.setDefaultDtRange();
  }

  setDefaultDtRange() {
    let dd = new Date();
    dd.setDate(dd.getDate() - DEFAULT_VISIT_DATE_FILTER);
    this.rangeDates = [dd, null];

    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getDataFromLocalStorage();
    this.getCurrentAgencyRole();
    this.allAdminRoles();
    this.isSuperAdminOrIOHUserAndAgencyAdmin();
    this.currentPath = this.activatedRoute.snapshot.url.join("/");
    this.sortSelectedVisits();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getClinicianList() {
    let currentAgency = this.localStorageService.get("currentAgency");
    let requestPayload = {
      agencyId: currentAgency ? currentAgency : {},
    };
    this.patientService.getClinicianList(requestPayload).subscribe((res) => {
      if (res.success) {
        this.clinicianFilter = res?.content?.clinicianList.map((clinician) => {
          return { label: clinician?.userList?.fullName, value: clinician?.userList?.id };
        });
        this.utilService.sortSelectItemByLabel(this.clinicianFilter);
        this.originalClinicianFilter = this.clinicianFilter.map((obj) => Object.assign({}, obj));
        // this.clinicianFilter.unshift({label: 'All', value: ''});
      }
    });
  }

  onSelectClinicianFilter() {
    this.setDataInLocalStorage("selectedClinician", this.selectedClinician);
    this.getSubmissionByPatients();
  }

  getDataFromLocalStorage() {
    const visitState = JSON.parse(this.localStorageService.get("visitState"));
    this.searchByPatient = visitState?.searchByPatient ? visitState?.searchByPatient : "";
    this.searchByCaseManager = visitState?.searchByCaseManager ? visitState?.searchByCaseManager : "";
    this.searchByClinician = visitState?.searchByClinician ? visitState?.searchByClinician : "";
    this.selectedSubmissionStatus = visitState?.selectedSubmissionStatus ? visitState?.selectedSubmissionStatus : "";
    this.selectedSubmissionTypes = visitState?.selectedSubmissionTypes ? visitState?.selectedSubmissionTypes : "";
    this.rangeDates = visitState?.rangeDates ? visitState?.rangeDates : null;
    this.selectedClinician = visitState?.selectedClinician ? visitState?.selectedClinician : [];
    this.selectedCaseManagerName = visitState?.selectedCaseManagerName ? visitState?.selectedCaseManagerName : [];

    if (this.selectedSubmissionTypes && this.selectedSubmissionTypes.length) {
      this.selectedSubmissionTypesForAPI = this.selectedSubmissionTypes.map((selectedSubmissionType) => {
        return selectedSubmissionType.value;
      });
    }

    // if (this.rangeDates && this.rangeDates.length && this.rangeDates[0] && visitState?.rangeDates) {
    //   let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    //   this.searchByVisitDates.startDate = this.utilService.convertToISOString(startDateFilter, true);
    //   this.rangeDates[0] = new Date(this.rangeDates[0]);
    // }

    // if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1] && visitState?.rangeDates) {
    //   let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);
    //   this.searchByVisitDates.endDate = this.utilService.convertToISOString(endDateFilter, false);
    //   this.rangeDates[1] = new Date(this.rangeDates[1]);
    // }

    // if (!visitState?.rangeDates) {
    this.rangeDates = [new Date(new Date().setDate(new Date().getDate() - DEFAULT_VISIT_DATE_FILTER)), new Date()];
    // }
    this.prePopulateDate();
    this.prePopulatePerPage();
  }

  prePopulateDate() {
    const global = JSON.parse(this.localStorageService.get("globalDates"));
    if (global) {
      this.searchByVisitDates.startDate = this.utilService.convertToISOString(new Date(global.startDate), true);
      this.searchByVisitDates.endDate = this.utilService.convertToISOString(new Date(global.endDate), false);
      this.rangeDates = [new Date(global.startDate), new Date(global.endDate)];
    }
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get("globalPageLimit"));
    if (global) {
      this.noOfRecordPerRow = global.perPage;
    }
  }

  setDataInLocalStorage(key, value) {
    const visitState: any = JSON.parse(this.localStorageService.get("visitState"));
    if (visitState) {
      visitState[key] = value;
      this.localStorageService.set("visitState", JSON.stringify(visitState));
    } else {
      let visitNewState = {};
      visitNewState[key] = value;
      this.localStorageService.set("visitState", JSON.stringify(visitNewState));
    }
  }

  resetPageAndFetchSubmissionByPatient(searchParam: string) {
    this.setDataInLocalStorage("searchByPatient", this.searchByPatient);
    this.setDataInLocalStorage("searchByCaseManager", this.searchByCaseManager);
    this.setDataInLocalStorage("searchByClinician", this.searchByClinician);

    if (searchParam.length >= this.defaultSearchMinCharacter || searchParam === "") {
      this.pageNo = 0;
      this.getSubmissionByPatients();
    } else {
      this.pageNo = 0;
      this.getSubmissionByPatients();
    }
  }

  onlyAdminRoles() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  // isSearchEmpty() {
  //   if (
  //     this.searchPatient === undefined ||
  //     this.searchPatient === null ||
  //     this.searchPatient === ''
  //   ) {
  //     this.pageNo = 0;
  //     this.getSubmissionByPatients();
  //   }
  // }

  selectVisitDate() {
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.searchByVisitDates = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
    this.pageNo = 0;

    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.getSubmissionByPatients();
    this.resetPaginator();

    this.utilService.setDataInLocalStorage("globalDates", "startDate", this.rangeDates[0]);
    this.utilService.setDataInLocalStorage("globalDates", "endDate", this.rangeDates[1]);
  }

  onCalendarClear() {
    this.selectedVisitDate = null;
    this.rangeDates = null;
    this.searchByVisitDates = null;
    this.pageNo = 0;

    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedVisitDate", this.selectedVisitDate);
    this.setDataInLocalStorage("rangeDates", this.rangeDates);
    this.setDataInLocalStorage("searchByVisitDates", this.searchByVisitDates);
    this.getSubmissionByPatients();
    this.resetPaginator();

    this.localStorageService.remove("globalDates");
  }

  openeSOCUploadPopup() {
    this.eSOCUploadDisplay = true;
    this.display = false;
  }

  eSOCUploadPopupClose() {
    this.eSOCUploadDisplay = false;
    this.display = false;
  }

  refreshListing() {
    this.getSubmissionByPatients();
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          this.currentUser = res;
        }
      }),
    );
  }

  getSubmissionByPatients() {
    try {
      //this.pageNo = 0;
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;

      let request = {
        pagination: {
          pageNumber: this.pageNo,
          pageSize: this.noOfRecordPerRow,
        },
        visitStatus: this.selectedSubmissionStatus,
        search: this.searchByPatient,
        orderBy: this.orderBy,
        order: this.sortOrder,
        agencyId: this.currentUser.currentUserRole === RoleID.Admin ? this.selectedAgency : this.currentAgency.agency,
        getUserId: this.currentUser.id,
        searchByVisitDate: this.searchByVisitDate,
        searchByVisitDates: this.searchByVisitDates,
        searchByCaseManager: this.searchByCaseManager ? this.searchByCaseManager : this.selectedCaseManagerName,
        searchByClinician: this.searchByClinician,
        visitTypes: this.selectedSubmissionTypesForAPI,
        clinicianId: this.selectedClinician,
      };
      this.subscriptions.push(
        this.patientService.getAllVisits(request).subscribe((res) => {
          this.submissionList = [];
          if (res?.success) {
            const updatedSubmissions = res?.content?.data.map((submission) => {
              const firstName = submission?.submissionData?.patient?.firstName ? submission?.submissionData?.patient?.firstName : "";
              const lastName = submission?.submissionData?.patient?.lastName ? submission?.submissionData?.patient?.lastName : "";
              const patientName = firstName + " " + lastName;
              submission.submissionData["episodeName"] = submission?.submissionData?.episodeName
                ? submission?.submissionData?.episodeName.replace("Episode ", "Ep")
                : "";
              submission.patientName = patientName;
              submission.errorCount = submission?.submissionData.issues.filter(
                (e: any) => e.type === QUESTIONAIR_TYPE_ERROR && e.crossRuleType !== IMPACT,
              ).length;
              submission.warningCount = submission?.submissionData.issues.filter(
                (e: any) => e.type === QUESTIONAIR_TYPE_WARN || (e.type === QUESTIONAIR_TYPE_WARNING && e.crossRuleType !== IMPACT),
              ).length;
              return {
                ...submission,
              };
            });
            this.submissionList = res?.content?.totalElements !== 0 ? updatedSubmissions : ([] ?? []);
            this.totalSubmission = res?.content?.totalElements ?? 0;
            this.setConsistencyWarningsCount();
            //this.canHighlightRow();
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
          this.loading = false;
        }),
      );
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.loading = false;
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  setConsistencyWarningsCount() {
    this.submissionList.forEach((submission) => {
      submission.submissionData.crossRulesIssuesCount = this.utilService.calculateInconsistencyWarnings(submission?.submissionData);
    });
  }

  onSubmissionStatusDropdownChange() {
    this.pageNo = 0;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedSubmissionStatus", this.selectedSubmissionStatus);
    this.getSubmissionByPatients();
    this.resetPaginator();
  }

  onSubmissionTypeDropdownChange() {
    this.pageNo = 0;
    this.setDataInLocalStorage("pageNo", this.pageNo);
    this.setDataInLocalStorage("selectedSubmissionTypes", this.selectedSubmissionTypes);

    if (this.selectedSubmissionTypes && this.selectedSubmissionTypes.length)
      this.selectedSubmissionTypesForAPI = this.selectedSubmissionTypes.map((selectedSubmissionType) => {
        return selectedSubmissionType.value;
      });
    else this.selectedSubmissionTypesForAPI = [];
    this.getSubmissionByPatients();
    this.resetPaginator();
  }

  sortSelectedVisits() {
    this.submissionTypes = this.utilService.sortSelectedVisits(this.submissionTypes, this.selectedSubmissionTypes);
  }

  openPatientSubmissionDetails(
    patientSubmissionDetails?: any,
    dob?: string,
    mrn?: string,
    firstName?: string,
    lastName?: string,
    totalErrorAndWarnings?: number,
    picture?: string,
    rowIndex?: number,
  ) {
    this.display = true;
    this.patientSubmission = patientSubmissionDetails;
    this.patientSubmission.questionnaire = this.utilService.extractQuestionAndAnswer(patientSubmissionDetails?.questionnaire);

    this.patientSubmission["firstName"] = patientSubmissionDetails?.submissionData?.patient?.firstName ?? (this.isSuperOrIohAdmin() ? ANONYMOUS : "");
    this.patientSubmission["lastName"] = patientSubmissionDetails?.submissionData?.patient?.lastName ?? (this.isSuperOrIohAdmin() ? ANONYMOUS : "");
    const patientFirstName = patientSubmissionDetails?.submissionData?.patient?.firstName ?? (this.isSuperOrIohAdmin() ? ANONYMOUS : "");
    const patientLastName = patientSubmissionDetails?.submissionData?.patient?.lastName ?? (this.isSuperOrIohAdmin() ? ANONYMOUS : "");
    this.patientSubmission["patientName"] = (patientFirstName ? patientFirstName : "") + " " + (patientLastName ? patientLastName : "");
    this.patientSubmission["mrn"] = patientSubmissionDetails?.submissionData?.patient?.mrn ?? "";
    this.patientSubmission["dob"] = patientSubmissionDetails?.submissionData?.patient?.dob ?? "";
    this.patientSubmission["totalErrorAndWarnings"] = this.patientSubmission.errorCount + this.patientSubmission.warningCount;
    this.patientSubmission["picture"] = picture;
    this.patientSubmission["patientId"] = patientSubmissionDetails?.patientId;
    this.patientSubmission["questionnaire"] = this.getVisitsQuestionnaire(patientSubmissionDetails);
    this.patientSubmission["userId"] = patientSubmissionDetails?.submissionData?.userId;
    this.patientSubmission["issues"] = patientSubmissionDetails?.submissionData?.issues;
    this.patientSubmission["issues"].forEach((issue) => {
      return (issue["message"] = reformatWithSpecialCharacters(issue?.message));
    });

    this.patientSubmission["issues"].forEach((issue) => {
      return (issue["questionDescription"] = questionDescription[issue?.question]);
    });

    reformatVisitsAnswersData(this.patientSubmission["issues"]);

    this.patientSubmissionRowSelected = rowIndex;
  }

  getVisitsQuestionnaire(patientSubmissionDetails) {
    const questionnaire = [];
    for (const key in patientSubmissionDetails?.submissionData.questionnaire) {
      questionnaire.push({
        question: key,
        answer: patientSubmissionDetails?.submissionData.questionnaire[key],
      });
    }
    return questionnaire;
  }

  isSuperAdminOrIOHUserAndAgencyAdmin() {
    this.hidePatientName = adminRoles.includes(this.currentUser.currentUserRole);
  }

  isSuperOrIohAdmin() {
    return adminRoles.includes(this.currentUser.currentUserRole);
  }

  showLocationDialog(visitDate: string, visitType: string) {
    //console.log(visitDate, visitType);
    this.selectedVisitDates = visitDate;
    this.selectedVisitType = visitType;
    this.initializeOptions();
    //console.log(this.options, this.patientSubmission);
    this.locationPopupDisplay = true;
  }

  initializeOptions() {
    const mapCoordinates = swapLatitudeAndLongitude(
      this.patientSubmission.submissionData?.latitude,
      this.patientSubmission.submissionData?.longitude,
    );
    const latitude = mapCoordinates?.latitude;
    const longitude = mapCoordinates?.longitude;

    this.options = {
      center: {
        lat: latitude, //this.patientSubmission.submissionData.latitude,
        lng: longitude, //this.patientSubmission.submissionData.longitude,
      },
      zoom: 12,
    };
    this.overlays = [
      new google.maps.Marker({
        position: {
          lat: +this.patientSubmission.submissionData.latitude,
          lng: +this.patientSubmission.submissionData.longitude,
        },
        title: "Selected Visit",
      }),
    ];
  }

  getLabelByValue(value: string): string {
    const status = submissionStatus?.find((status) => status?.value === value);
    return status ? status.label : "";
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage("globalPageLimit", "perPage", event.rows);
      this.pageNo = event.page;
      this.noOfRecordPerRow = event.rows;
      this.getSubmissionByPatients();
    }
  }

  statusColor(status) {
    if (status?.includes("_")) {
      return status?.replace(/_/g, "");
    } else {
      return status?.replace(/-/g, "");
    }
  }

  getCurrentAgencyRole() {
    this.subscriptions.push(
      this.userDataManager.activeAgencyRole$.subscribe((currentRole) => {
        this.getCaseManagerList();
        this.currentAgency = currentRole;
        if (this.currentAgency.role == RoleID.Admin) {
          this.currentAgency.agency = "";
        }
        this.getClinicianList();
        this.getSubmissionByPatients();
      }),
    );
  }

  onSlideBarClose() {
    this.display = false;
  }

  reprocessedObject(event) {
    const index = this.submissionList.findIndex((submission) => submission._id == this.patientSubmission._id);

    if (index != -1) {
      this.submissionList[index].submissionData = { ...this.submissionList[index].submissionData, issues: event?.submission?.issues };
      this.submissionList[index].errorCount = event?.errorCount;
      this.submissionList[index].warningCount = event?.warningCount;
    }
  }

  allAdminRoles() {
    this.allowAdminRoles = OnlyAdminAllowedRoles.includes(this.currentUser?.currentUserRole);
  }

  resetPaginator() {
    this.paginator.changePageToFirst(event);
  }

  routeTo(e) {
    this.router.navigate(["/" + e]);
  }

  clearSearchAndReload(searchParam: string) {
    if (searchParam === undefined || searchParam === null || searchParam === "") {
      this.getSubmissionByPatients();
      this.resetPaginator();
    }
  }

  onSelectCaseManagerFilter() {
    this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
    this.getSubmissionByPatients();
  }

  getCaseManagerList() {
    let currentAgency = this.localStorageService.get("currentAgency");
    let requestPayload = {
      agencyId: currentAgency ? currentAgency : {},
    };
    this.patientService.getCaseManagerList(requestPayload).subscribe((res) => {
      if (res?.success) {
        this.caseManagerFilter = res?.content?.distinctCaseManagers
          .filter((caseManager) => caseManager?.fullName != null && caseManager?.fullName.trim() !== "")
          .map((caseManager) => {
            return { label: caseManager?.fullName, value: caseManager?.firstName ? caseManager?.firstName : caseManager?.lastName };
          });

        this.utilService.sortSelectItemByLabel(this.caseManagerFilter);

        this.originalCaseManagerFilter = this.caseManagerFilter.map((obj) => Object.assign({}, obj));
      }
    });
  }

  customDateSort() {
    this.sortOrder = this.sortOrder == "-1" ? "1" : "-1";
    this.orderBy = "visitDate";
    this.getSubmissionByPatients();
  }

  canHighlightRow() {
    if (this.submissionList && this.submissionList.length)
      this.submissionList.forEach((submissionList) => {
        if (this.currentAgency?.role !== RoleID.CaseManager) {
          if (submissionList?.submissionData?.status == "in-progress" || submissionList?.submissionData?.status == "waiting_for_review") {
            const visitDate = new Date(submissionList?.submissionData?.visitDate);
            const currentDate = new Date();
            const difference: number = (currentDate.getTime() - visitDate.getTime()) / (1000 * 60 * 60);

            submissionList.canHighlight = difference > this.hoursInFourDays;
          } else submissionList.canHighlight = false;
        } else submissionList.canHighlight = false;
      });
  }

  clearSelectedValues(selectedValues: any[]) {
    if (selectedValues && selectedValues.length) {
      selectedValues.splice(0, selectedValues.length);
      this.setMultiSelectValuesInLocalStorage();
      this.onSubmissionTypeDropdownChange();
    }
  }

  setMultiSelectValuesInLocalStorage() {
    this.setDataInLocalStorage("selectedSubmissionTypes", this.selectedSubmissionTypes);
    this.setDataInLocalStorage("selectedClinician", this.selectedClinician);
    this.setDataInLocalStorage("selectedCaseManagerName", this.selectedCaseManagerName);
  }

  sortSelectedValues() {
    this.clinicianFilter = this.utilService.sortSelectedValuesOfMultiSelect(this.originalClinicianFilter, this.selectedClinician);
    this.caseManagerFilter = this.utilService.sortSelectedValuesOfMultiSelect(this.originalCaseManagerFilter, this.selectedCaseManagerName);
  }
}
