import { Agency } from "./agency";

export interface LoginUser {
  email: string;
  password: string;
}

export interface User {
  id?: string;
  aws_cognito_id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phone?: { countryCode: string; number: string };
  fax?: { countryCode: string; number: string };
  status?: string;
  email?: string;
  is_super_admin?: boolean;
  is_IOH_admin?: boolean;
  is_company_admin?: boolean;
  is_ioh_user?: boolean;
  agency_roles?: AgencyRole[];
  picture?: string;
  defaultRole?: string;
  address?: Address;
  password?: string;
  companyId?: string;
  createdAt?: Date;
  createdBy?: string;
  creator?: string;
  currentUserRole?: string;
  userSettings?: UserSettings;
  lastLogin?: Date;
  agent?: string;
  _id?: string;
  permissions?: string[];
  androidVersion?: string;
  desktopVersion?: string;
  platform?: string;
  lastActivity?: Date;
  lastAction?: Date;
  isIOHHelp?: boolean;
  agencyObject?: Agency[];
  changePassword?: Date;
  provider?: string;
  noActivity?: boolean;
  registrationStatus?: string;
}

export interface UserSettings {
  logging: boolean;
  loggingView: boolean;
  isMultiAgenciesAllowed: boolean;
  mfa: boolean;
  experimentMode: number
}

export const userSettingsValues = {
  logging: true,
  loggingView: false,
  isMultiAgenciesAllowed: false,
  mfa: false,
  experimentMode: 0
}

export interface Address {
  state: string;
  country: string;
  city: string;
  line1: string;
  line2?: string;
  postalCode: string;
}

export interface AgencyRole {
  agency: { agencyID: string; agencyName: string };
  roles: string[];
  defaultAgency: boolean;
}

export interface Response<T> {
  success: boolean;
  content?: {
    totalElements: number;
    totalUsersCount: number;
    data: T;
  };
  returnCode: number;
  errorMessage?: string;
  message?: string;
}

export const userStatusValues = [
  { label: "All", value: "" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const bulkUploadTemplateValues = [{ label: "Download Template", value: "Download Template" }];

export const IOHUserRows = [
  { value: "User", label: "User" },
  { value: "Email", label: "Email" },
  { value: "Phone", label: "Phone" },
  { value: "Created By", label: "Created By" },
  { value: "Created At", label: "Created At" },
  { value: "Status", label: "Status" },
  { value: "Last Action", label: "Last Action" },
  { value: "Last Activity", label: "Last Activity" },
];

export const defaultSelectedIOHUserRows = [
  "User",
  "Email",
  "Phone",
  "Agency",
  "Roles",
  "Created By",
  "Created At",
  "Status",
  "Last Action",
  "Last Activity",
];

export const NonIOHUserRows = [
  { value: "Agency", label: "Agency" },
  { value: "User", label: "User" },
  { value: "Email", label: "Email" },
  { value: "Phone", label: "Phone" },
  { value: "Roles", label: "Roles" },
  { value: "Provider", label: "Provider" },
  { value: "Created By", label: "Created By" },
  { value: "Created At", label: "Created At" },
  { value: "Status", label: "Status" },
  { value: "Last Action", label: "Last Action" },
  { value: "Last Activity", label: "Last Activity" },
];
export const UserFilterDropdown = [
  { value: "Activity Stopped", label: "No Action" },
  // { value: "GPS", label: "GPS" },
  { value: "Floating", label: "Floating" },
  { value: "Push", label: "Push" },
  { value: "Read", label: "Read" },
];

export const UserFilterDropdownPermissions = ["Floating", "Push", "Read"];
export enum UserStatus {
  active = "Active",
  inactive = "Inactive",
}
export const defaultSelectedNonIOHUserRows = [
  "Agency",
  "User",
  "Email",
  "Phone",
  "Roles",
  "Provider",
  "Created By",
  "Created At",
  "Status",
  "Last Logged",
];
