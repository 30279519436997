import {EnvironmentModel, IOHURLS} from "./environment.model";
import {Endpoints} from "./endpoints";

export const IOH: IOHURLS = {
  patientServiceBaseURL: 'https://portal.iohealthtech.com',
  userServiceBaseURL: 'https://portal.iohealthtech.com',
  rulesServiceBaseUrl: 'https://portal.iohealthtech.com',
}

const endpoints = new Endpoints()
export const environment: EnvironmentModel= {
  production: false,
  cognito: {
    userPoolId: 'us-east-2_jSqJgpb76',
    userPoolWebClientId: '7tdv91p6bvl4jv446kbgcu1abo',
    region: 'us-east-2'
  },
  endPoints: { ...endpoints.getGenericEndpoints()},
  awsCredentials: {
    region: "us-east-2",
    signatureVersion: "v4"
  }
}
